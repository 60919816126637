import { useContext } from "react";
import theme from "@config/theme";
import { TrackedLink } from "@components/analytics/TrackedLink";
import { LayoutContext } from "@components/layout/Layout";
import YouTubeLogo from "@components/layout/footerIconLogos/YouTubeLogo";
import TwitterLogo from "@components/layout/footerIconLogos/TwitterLogo";
import HacksterLogo from "@components/layout/footerIconLogos/HacksterLogo";
import GitHubLogo from "@components/layout/footerIconLogos/GitHubLogo";
import LinkedInLogo from "@components/layout/footerIconLogos/LinkedInLogo";

const Footer = () => {
  const { REPLCollapsed } = useContext(LayoutContext);

  return (
    <footer className={`page-footer ${REPLCollapsed ? "" : "replExpanded"}`}>
      <div className="page-footer-container">
        <div className="copyright-desktop">
          © {new Date().getFullYear()} Blues Inc.
        </div>
        <div className="social">
          <TrackedLink
            data-analytics-location="footer"
            href="https://github.com/blues"
          >
            <span className="footer-icon">
              <GitHubLogo />
            </span>
          </TrackedLink>
          <TrackedLink
            data-analytics-location="footer"
            href="https://www.linkedin.com/company/buildwithblues/"
          >
            <span className="footer-icon">
              <LinkedInLogo />
            </span>
          </TrackedLink>

          <TrackedLink
            data-analytics-location="footer"
            href="https://www.youtube.com/@BuildWithBlues"
          >
            <span className="footer-icon">
              <YouTubeLogo />
            </span>
          </TrackedLink>
          <TrackedLink
            data-analytics-location="footer"
            href="https://x.com/buildwithblues"
          >
            <span className="footer-icon">
              <TwitterLogo />
            </span>
          </TrackedLink>
          <TrackedLink
            data-analytics-location="footer"
            href="https://www.hackster.io/blues-wireless"
          >
            <span className="footer-icon">
              <HacksterLogo />
            </span>
          </TrackedLink>
        </div>

        <div className="copyright-info-container">
          <div className="copyright-mobile">
            © {new Date().getFullYear()} Blues Inc.
          </div>
          <div className="info">
            <TrackedLink
              data-analytics-location="footer"
              href="https://blues.com/terms"
            >
              <span>Terms</span>
            </TrackedLink>
            <TrackedLink
              data-analytics-location="footer"
              href="https://blues.com/privacy"
            >
              <span>Privacy</span>
            </TrackedLink>
          </div>
        </div>
      </div>

      <style jsx>{`
        .page-footer {
          padding: 0 0 3.5rem 0;
          z-index: ${theme.zIndex.footer};
          grid-area: footer;
        }

        .page-footer-container {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          padding-left: 15px;
          padding-right: 15px;
          max-width: 1540px;
          margin: auto;
        }

        .copyright-desktop,
        .info,
        .social {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
        }

        .copyright-mobile {
          display: none;
        }

        .footer-icon {
          color: ${theme.colors.gray3};
        }

        .footer-icon:hover {
          color: ${theme.colors.gray1};
        }

        .page-footer :global(a),
        .page-footer :global(a:hover),
        .page-footer :global(a:visited),
        .copyright-mobile {
          padding: 0 0.75rem;
          color: ${theme.colors.black};
        }

        @media screen and (max-width: ${theme.breakpoints
            .condenseTopNavBar}px) {
          .page-footer-container {
            flex-direction: column;
            align-items: center;
          }

          .copyright-desktop {
            display: none;
          }

          .copyright-mobile {
            display: block;
          }

          .copyright-info-container {
            display: flex;
          }

          .copyright-info-container,
          .social {
            margin-top: 1.5rem;
          }
        }

        // largest width
        @media screen and (min-width: ${theme.breakpoints.lg + 1}px) {
          .page-footer.replExpanded {
            width: calc(100% * 0.6);
          }
        }
      `}</style>
    </footer>
  );
};

export default Footer;
