import { useEffect, useState } from "react";
import theme from "@config/theme";
import { useWindowWidth } from "@react-hook/window-size";
import { MenuItem } from "@components/layout/Menu";
import { Tab } from "@components/menu/Tab";

const ResourcesMenu = () => {
  const [keepDropDownOpen, setKeepDropDownOpen] = useState(false);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    // if viewport width less than 870px wide, remainOpen is true
    // so the dropdown menu will stay open until it is explicitly closed again or the user navigates to a new page
    // no layout shift caused initially, it purely affects the dropdown menu's behavior based on screen size
    if (windowWidth <= theme.breakpoints.condenseTopNavBar) {
      setKeepDropDownOpen(true);
    } else {
      // if viewport width is greather than 870px wide, remainOpen is false
      // so the dropdown menu will auto close when a user clicks a link in it or clicks anywhere else on the page
      setKeepDropDownOpen(false);
    }
  }, [windowWidth]);

  const menuItem: MenuItem = {
    title: "Resources",
    type: "section",
    level: 1,
    selected: false,
    remainOpen: keepDropDownOpen,
    items: [
      {
        title: "Blog",
        subtitle: "Technical articles for developers",
        type: "link",
        href: "/blog",
        items: [],
        level: 2,
        selected: false,
      },
      {
        title: "Newsletter",
        subtitle: "The monthly Blues developer newsletter",
        type: "link",
        href: "/developer-news",
        items: [],
        level: 2,
        selected: false,
      },
      {
        title: "Terminal",
        subtitle: "Connect to a Notecard in your browser",
        type: "link",
        href: "/terminal",
        items: [],
        level: 2,
        selected: false,
      },
      // {
      //   title: "Developer Certification",
      //   subtitle: "Get certified on wireless connectivity with Blues",
      //   type: "link",
      //   href: "/developer-certification",
      //   items: [],
      //   level: 2,
      //   selected: false,
      // },
      {
        title: "Webinars",
        subtitle: "Listing of Blues technical webinars",
        type: "link",
        href: "/webinars",
        items: [],
        level: 2,
        selected: false,
      },
      {
        title: "Blues.com",
        type: "link",
        href: "https://blues.com",
        items: [],
        level: 2,
        selected: false,
      },
      {
        title: "Notehub.io",
        type: "link",
        href: "https://notehub.io",
        items: [],
        level: 2,
        selected: false,
      },
    ],
  };

  return (
    <>
      <div className="resourcesMenuContainer">
        <Tab {...menuItem} />
      </div>
      <style jsx>{`
        .resourcesMenuContainer :global(.tabContainer .dropdown-content) {
          margin: -0.5rem 0 0 1.5rem;
        }

        .resourcesMenuContainer :global(.dropdown-link) {
          max-width: unset;
        }
      `}</style>
    </>
  );
};

export default ResourcesMenu;
